//blog-post-temp.js
import React from "react"
import { graphql } from "gatsby"
import SEO from '../components/seo'
import Layout from "../components/layout"
import ReadMore from "../components/btnReadMore"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import _ from "lodash"
import { NewsWrapper, NewsPostHeader, NewsPostThumbImg, NewsPostTtl, NewsPostMeta, NewsPostSub, NewsDate, NewsPostCat, BtnTwitterShare } from "../style/common/common"
import { MdBody } from "../style/common/markdown"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const NewsPost = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const thumbnail = getImage(post.frontmatter.thumb[0])
  const { breadcrumb: { crumbs } } = pageContext
  const ogpSrc = thumbnail.images.fallback.src
  const currentPath = data.site.siteMetadata.siteUrl + location.pathname

  let excerptTtl
  if (post.frontmatter.title.length > 20) {
    const str = post.frontmatter.title.substr(0, 19)
    excerptTtl = `${str}...`
  } else {
    excerptTtl = post.frontmatter.title
  }

  return (
    <Layout
      location={location}
    >
      <SEO
        title={`${post.frontmatter.title} | ${data.site.siteMetadata.title}`}
        image={ogpSrc}
      />
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" / "
        crumbLabel={excerptTtl}
      />
      <NewsWrapper>
        <NewsPostHeader>
          <NewsPostThumbImg>
            <GatsbyImage
              image={thumbnail}
              alt={post.frontmatter.title}
            />
          </NewsPostThumbImg>
          <NewsPostTtl>
            {post.frontmatter.title}
          </NewsPostTtl>
          <NewsPostSub>
            <NewsPostMeta>
              <NewsDate>
                {post.frontmatter.date}
              </NewsDate>
              <NewsPostCat>
                {post.frontmatter.category}
              </NewsPostCat>
            </NewsPostMeta>
            <BtnTwitterShare>
              <a href={`https://twitter.com/intent/tweet?url=${currentPath}&text=${post.frontmatter.title}&hashtags=素粒社`} target="_blank" rel="noopener noreferrer">
                <span><img src={`../../icons/icon-twitter.svg`} alt="twitter"/></span>ツイート
              </a>
              </BtnTwitterShare>
          </NewsPostSub>
        </NewsPostHeader>
        <MdBody dangerouslySetInnerHTML={{ __html: post.html }}/>
      </NewsWrapper>
      <ReadMore to="/news/" label="もどる" />
    </Layout>
  )
}
export default NewsPost

export const query = graphql`
  query PostQuery($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
    ) {
      html
      frontmatter {
        title
        date(formatString: "YYYY.MM.DD")
        category
        thumb {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
      }
    }
  }
`
